import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Image from "./image";
import Hidden from "@material-ui/core/Hidden";
import ButtonLink from "./button-link";
import Bar from "./bar";

const Hero = () => {
  const readMoreLink = {
    id: 1,
    link: "/over-mij",
    title: "Lees meer",
  };

  const appointmentBtn = {
    id: 2,
    link: "/contact",
    title: "Ik wil een afspraak",
  };

  return (
    <Grid container spacing={3} style={{ marginTop: 50 }}>
      <Grid
        data-aos="fade-left"
        item={true}
        xs={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <div style={{ width: "100%" }}>
          <Image style={{ margin: "auto" }} maxWidth={450} alt="Mediatio - Concilium - Maarten" filename="e.png" />
        </div>
        <Bar />
        <Typography variant="subtitle1" style={{}}>
          Gedreven door dialoog.
        </Typography>

        {/*<!-- Profile picture -->*/}
        <Grid
          data-aos="fade-left"
          item={true}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Grid item={true} container direction="row" justifyContent="center" alignItems="center">
            <Grid
              style={{ maxWidth: 400 }}
              item={true}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div style={{ display: "flex", margin: 5 }}>
                <ButtonLink btnLink={readMoreLink}></ButtonLink>
              </div>
              <div style={{ display: "flex", margin: 5 }}>
                <ButtonLink data-aos="fade-up" btnLink={appointmentBtn}></ButtonLink>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Hero;
