import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import Bar from "../components/bar";
import Hero from "../components/hero";
import Image from "../components/image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Services from "../components/services";
import theme from "../theme";

const IndexPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Concilium bemiddeling" />

        <Hero />
        <Services />
        <Grid
          data-aos="fade-left"
          item={true}
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ paddingLeft: 40, paddingRight: 40 }}
        >
          <Typography variant="subtitle1" style={{marginTop:20}}>
            Ik maak sinds 1 april 2023 deel uit van het advocatenkantoor Kantoor 33, gelegen te 9600 Ronse, Grote Markt
            33. Ik zal daar mijn bemiddelings- en andere juridische activiteiten, zoals vermeld op deze website, verder
            uitoefenen.
          </Typography>
        </Grid>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
);

export default IndexPage;
