import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ServiceCard from "./service-card";
import Bar from "./bar";
import ButtonLink from "./button-link";
import Image from "./image";

const Services = () => {

  let services = [
    {
      id: 1,
      delay: 150,
      image: "bemiddeling-en-advies.png",
      link: "bemiddeling-en-advies",
      title: "Bemiddeling en advies",
      description:
        "Kom tot een oplossing die in het belang is van alle partijen.",
    },
    {
      id: 2,
      delay: 300,
      image: "Tarieven.png",
      link: "/tarieven",
      title: "Tarieven",
      description: "Leer meer over de kosten van een bemiddeling.",
    },
    {
      id: 3,
      delay: 450,
      image: "Contact.png",
      link: "/contact",
      title: "Contact",
      description:
        "Neem contact op voor vragen of een afspraak.",
    },
  ];

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
    >
      {services.map(service => (
        <Grid
          data-aos-duration="750"
          data-aos="zoom-in"
          data-aos-delay={service.delay}
          style={{ display: "inline-grid" }}
          item
          xs={12}
          sm={4}
          key={service.id}
        >
          <ServiceCard service={service} />
        </Grid>
      ))}
     
    </Grid>
  );
};
export default Services;
