import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Image from "./image";
import { makeStyles } from "@material-ui/styles";
import { Link } from "gatsby";

interface IProps {
  service: Service;
}

interface Service {
  title: string;
  image: string;
  description: string;
  link: string;
}

const useStyles = makeStyles(theme => ({
  aaa: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#c49a6c",
      textDecoration: "underline",
    },
  },
  outerDiv: {
    padding: theme.spacing(4),
    // "&:hover": {
    //   backgroundColor: theme.palette.grey[100],
    // },
  },
  addIcon: () => ({
    height: 50,
    width: 50,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
  }),
}));

const ServiceCard = ({ service, ...props }: IProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.outerDiv}>
      <CardContent style={{ textAlign: "start", minHeight: 200 }}>
        <a
          href={service.link}
          style={{
            cursor: "pointer",
            width: 40,
            padding: "10px 10px 10px 0px",
          }}
        >
          <Image maxWidth={100} alt={service.title} filename={service.image} />
        </a>
        <Typography variant="h6" color="inherit">
          <Link key={service.link} to={service.link} className={classes.aaa}>
            {service.title}
          </Link>
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {service.description}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default ServiceCard;
