import { red } from "@material-ui/core/colors";
import { createTheme } from '@material-ui/core/styles';
// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@material-ui/core/styles';

declare module '@material-ui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  typography: {
    fontFamily: "\"Quicksand\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
    subtitle1: {
      color: "#7D7987",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: "normal"
    }
  },
  palette: {
    primary: {
      main: "#d7bea8",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "#7D7987 !important",
      disabled: "#CCC"
    },

  },
  shape: {
    borderRadius: 20,
  },
});

export default theme;
