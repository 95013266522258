import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "gatsby";
import React from "react";

interface IProps {
  btnLink: ButtonLinkProp;
}

interface ButtonLinkProp {
  title: string;
  id: string | number;
  link: string;
}

const useStyles = makeStyles(theme => ({
  buttonLink: {
    
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    padding: "10px 20px 10px 20px",
    border: "1px solid #0b9444",
    textDecoration: "none",
    color: "#0b9444",
    "&:hover": {
      border: "1px solid white",
      backgroundColor: "#c49a6c",
      color: "white",
    },
  }
}));

const ButtonLink  = ({ btnLink, ...props }: IProps) => {
  const classes = useStyles();
  return (
    <Link {...props} key={btnLink.id} to={btnLink.link} className={classes.buttonLink}>
      <Typography variant="body2">
        {btnLink.title}
      </Typography>
    </Link>
  );
};
export default ButtonLink;
